module directives {
    export module applicationcore {
        interface IDateDirectiveScope extends ng.IScope {
            dateOptions: any,
            toggleDatePicker(): void,
            dateChange(date: Date): void,
            ngChange(data: any): void,
            isOpen: boolean,
            ngModel: moment.Moment,
            javascriptDate: Date,
            format: string,
            minDate: moment.Moment;
            invalidDate: boolean;
            showTime: boolean;
            timeHtml: string;
            minuteStep: number;
        }

        export class dateDirective implements ng.IDirective {
            public restrict: 'E';
            public template = `
                        <form name="inputForm">
                            <p class="input-group input-group-sm has-feedback" ng-class="{'has-error': inputForm[name].$invalid || !javascriptDate && ngRequired || invalidDate}">
                              <input type="text" name="{{name}}" datepicker-options="dateOptions" datepicker-append-to-body="true" class="form-control" uib-datepicker-popup="{{format}}" ng-click="toggleDatePicker()" 
                              ng-model="javascriptDate" ng-change="dateChange()" is-open="isOpen" close-text="Close" ng-required="ngRequired"  ng-disabled="ngDisabled"/>
                              <span class="input-group-btn" ng-if="showTime">
                                <button uib-popover-template="timeHtml" popover-title="Time" popover-append-to-body="true" type="button" class="btn btn-default" popover-trigger="'outsideClick'" ng-disabled="ngDisabled"><i class="fa fa-clock-o"></i></button>
                              </span>
                              <span class="input-group-btn">
                                <button class="btn btn-default" ng-click="toggleDatePicker()" type="button" ng-disabled="ngDisabled"><i class="fa fa-calendar"></i></button>
                              </span>
                              <span class="input-group-addon" ng-if="leadTime">
                                <span>{{leadTime}}</span>
                              </span>
                            </p>
                        </form>
                        <script type="text/ng-template" id="timePopover.html">
                            <div>
                                <div uib-timepicker ng-model="javascriptDate" ng-change="dateChange(javascriptDate)" hour-step="1" minute-step="minuteStep" show-meridian="false"></div>
                            </div>
                        </script>
                        `;
            public scope = {
                format: "@",
                ngModel: "=",
                ngChange: "&?",
                name: "@",
                ngRequired: "=",
                leadTime: "=",
                minDate: '=',
                showTime: '=?',
                minuteStep: '=?',
                ngDisabled: "=?"
            }

            constructor(private uiGridEditConstants: uiGrid.edit.IUiGridEditConstants, private $timeout: ng.ITimeoutService) {
            }

            link = ($scope: IDateDirectiveScope, $element: ng.IAugmentedJQuery) => {
                $scope.isOpen = false;

                if($scope.showTime) {
                    if(!$scope.minuteStep) {
                        $scope.minuteStep = 15;
                    }

                    $scope.timeHtml = `timePopover.html`;
                }

                $scope.toggleDatePicker = () => {
                    $scope.dateOptions = {
                        minDate: $scope.minDate ? $scope.minDate.toDate() : undefined
                    };
                    $scope.isOpen = !$scope.isOpen;
                }

                $scope.$watch("ngModel", () => {
                    if ($scope.ngModel) {
                        $scope.javascriptDate = new Date($scope.ngModel.format("YYYY/MM/DD HH:mm"));
                    } else {
                        $scope.javascriptDate = undefined;
                    }
                }, true);

                $scope.dateChange = (timeChange: Date) => {

                    if(timeChange) {
                        $scope.javascriptDate = timeChange;
                    }

                    if($scope.javascriptDate === undefined)
                        $scope.invalidDate = true;
                    else
                        $scope.invalidDate = false;
                    if ($scope.javascriptDate) {
                        $scope.ngModel = moment.utc({ y: $scope.javascriptDate.getFullYear(), M: $scope.javascriptDate.getMonth(), d: $scope.javascriptDate.getDate(), h: $scope.javascriptDate.getHours(), m: $scope.javascriptDate.getMinutes() });
                        this.$timeout(() => {
                            $scope.$emit(this.uiGridEditConstants.events.END_CELL_EDIT);
                        });
                    } else {
                        $scope.ngModel = null;

                        this.$timeout(() => {
                            $scope.$emit(this.uiGridEditConstants.events.END_CELL_EDIT);
                        });
                    }

                    if (angular.isDefined($scope.ngChange))
                        $scope.ngChange({ newValue: $scope.ngModel });
                   
                }
            }

            static factory(): ng.IDirectiveFactory {
                const directive = (uiGridEditConstants, $timeout) => new dateDirective(uiGridEditConstants, $timeout);
                directive.$inject = ['uiGridEditConstants', '$timeout'];

                return directive;
            }
        }

        export class uiDatepickerWrapper implements ng.IDirective {

            constructor(private $document: ng.IDocumentService, private uiGridEditConstants: uiGrid.edit.IUiGridEditConstants) {

            }

            link = (scope, element: angular.IRootElementService, attrs, ngModelController: ng.IController, $select) => {
                this.$document.on('click', (evt) => {
                    if (($(evt.target).closest('.uib-datepicker')).length === 0) {
                        scope.$emit(this.uiGridEditConstants.events.END_CELL_EDIT);
                        this.$document.off('click');
                    }
                });
            }

            static factory(): ng.IDirectiveFactory {
                const directive = ($document, uiGridEditConstants) => new uiDatepickerWrapper($document, uiGridEditConstants);
                directive.$inject = ['$document', 'uiGridEditConstants'];

                return directive;
            }
        }

        angular.module("app").directive("gtsDate", dateDirective.factory());
        angular.module("app").directive("uiDatepickerWrapper", uiDatepickerWrapper.factory());
    }
}